<template>
  <div>
    <div id="page-content">
      <div class="dispflex" style="align-items: baseline">
        <h1 class="hdrtitle ml-2" style="margin-top: 0px;">Fees Management</h1>
      </div>
      <div class="row mt-2">
        <div class="col-md-12 col-lg-12 col-sm-12">
          <div class="widjet m-2">
            
            <div class="tbllist mt-1">
                <div class="crtfeeplancont">
                  <div class="widjethdr dispflex" style="align-items: baseline">
                    <div style="width: auto">
                      <h1 style="margin: 0">Overall Discount & Penalties</h1>
                    </div>
                    <div class="mr-1" >
                      <button 
                        class="btn gry_bg tooltipt mpopup twoicn"
                        v-b-modal.addDiscount
                      >
                        <i class="fas fa-tags user1"></i>
                        <p class="user2"><i class="fas fa-plus"></i></p>
                       
                        <span class="tooltiptext">Add Discount</span>
                      </button>
                      <button 
                        class="btn gry_bg tooltipt mpopup twoicn"
                        v-b-modal.addPenalty
                      > 
                      <i class="fas fa-clipboard-list user1"></i>
                       <p class="user2"><i class="fas fa-plus"></i></p>
                       <!-- <i class="fas fa-clipboard lfticn"></i>
                        <i
                          class="fas fa-rupee-sign centericn"
                          style="right: 0.96rem; top: 0.8rem"
                        ></i>
                        <i class="fas fa-plus rgticn" style="top: 0.3rem"></i>-->
                        <span class="tooltiptext">Add Penalty</span>
                      </button>
                     
                    </div>
                  </div>
                  <div class="tbllist mt-1">
                    <div class="radtabs">
                      <div class="radtab">
                        <input
                          type="radio"
                          id="radtab-1"
                          name="radtab-group-1"
                          :checked="discountTabActive"
                        />
                        <label for="radtab-1" class="spacing">Discount</label>
                        <div class="form-row tabcontent" v-if="feePlanDetails.discount && feePlanDetails.discount.mode">
                          <div class="w-100">
                            <div class="restable">
                              <div class="resrow resheader">
                                 <div class="cell">Mode</div>
                                <div class="cell">Amount / Percentage</div>
                                <div class="cell">Term Paid</div>
                                <div class="cell">Action</div>
                              </div>
                              <div class="resrow">
                                 <div class="cell" data-title="Applied for">
                                  {{ feePlanDetails.discount.mode }}
                                </div>
                                <div
                                  class="cell"
                                  data-title="Discount Amount by"
                                >{{ feePlanDetails.discount.mode == 'Rs($)' ? feePlanDetails.discount.discountAmountValue : feePlanDetails.discount.discountPercentageValue }}
                                </div>
                                <div class="cell" data-title="Term Paid">
                                  {{ feePlanDetails.discount.termPaid }}
                                </div>
                                <div class="cell" data-title="Action">
                                  
                                  <button :disabled="feePlanDetails.status == 'Approved' || feePlanDetails.status == 'In Review' || feePlanDetails.status == 'Active'" class="btn round orange tooltipt" @click.prevent="editDetails('discount')">
                                    <i class="fas fa-edit"></i
                                    ><span class="tooltiptext">Edit</span>
                                  </button>
                                  <button :disabled="feePlanDetails.status == 'Approved' || feePlanDetails.status == 'In Review' || feePlanDetails.status == 'Active'" @click.prevent="removeAction('discount')" class="btn round red tooltipt">
                                    <i class="far fa-trash-alt"></i
                                    ><span class="tooltiptext">Delete</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="radtab">
                        <input
                          type="radio"
                          id="radtab-2"
                          name="radtab-group-1"
                          :checked="penalityTabActive"
                        />
                        <label for="radtab-2" class="spacing">Penality </label>
                        <div class="form-row tabcontent" v-if="feePlanDetails.penalty && feePlanDetails.penalty.mode">
                          <div class="w-100">
                            <div class="restable">
                              <div class="resrow resheader">
                               <div class="cell">Mode</div>
                                <div class="cell">Amount / Percentage</div>
                                <div class="cell">Paid After</div>
                                <div class="cell">Action</div>
                              </div>
                             <div class="resrow" >

                                <div class="cell" data-title="Applied for">
                                  {{ feePlanDetails.penalty.mode }}
                                </div>
                                <div
                                  class="cell"
                                  data-title="Discount Amount by"
                                >{{ feePlanDetails.penalty.mode == 'Rs($)' ? feePlanDetails.penalty.penaltyAmountValue : feePlanDetails.penalty.penaltyPercentageValue }}
                                </div>
                                <div class="cell" data-title="Term Paid">
                                  {{ feePlanDetails.penalty.paidAfter == '1' ?  feePlanDetails.penalty.paidAfter + " " + 'Day' : feePlanDetails.penalty.paidAfter + " " + 'Days' }}
                                </div>
                                <div class="cell" data-title="Action">
                                 
                                  <button :disabled="feePlanDetails.status == 'Approved' || feePlanDetails.status == 'In Review' || feePlanDetails.status == 'Active'" class="btn round orange tooltipt" @click.prevent="editDetails('penality')">
                                    <i class="fas fa-edit"></i
                                    ><span class="tooltiptext">Edit</span>
                                  </button>
                                   <button :disabled="feePlanDetails.status == 'Approved' || feePlanDetails.status == 'In Review' || feePlanDetails.status == 'Active'" @click.prevent="removeAction('penality')" class="btn round red tooltipt">
                                    <i class="far fa-trash-alt"></i
                                    ><span class="tooltiptext">Delete</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                     
                      <div class="radtab" style="display: none">
                        <input
                          type="radio"
                          id="radtab-4"
                          name="radtab-group-1"
                        />
                        <label for="radtab-4" class="spacing">Rebatsses</label>
                        <div class="form-row tabcontent">
                          <div
                            class="form-group input-group col-md-6 pr-md-3 mt-3"
                          >
                            <span class="has-float-label">
                              <input
                                list="rebsltmodelist"
                                
                                onfocus="this.value=''"
                                onchange="this.blur();"
                                class="form-control form-input"
                                id="rebsltmodeid"
                                placeholder="Select Mode"
                                
                              />
                              <label for="rebsltmodeid"
                                >Select Mode
                                <span class="required">*</span></label
                              >
                              <datalist id="rebsltmodelist">
                                <option value="Percentage(%)"></option>
                                <option value="Rs($)"></option>
                              </datalist>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="widjetfooter text-right dk_iconsml mt-3">
                  <button
                    type="button"
                    id="prevBtn"
                    class="btn btnsml"
                   @click="$router.go(-1)"
                  >
                   <i class="fa fa-arrow-left"></i> Back
                  </button>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>

     <!--model for add discount-->
      <b-modal id="addDiscount" >
            <div class="contact-form">
               <h2>Add Discount</h2>
               <br>
               <div>
                  <form data-vv-scope="discountValidate" id="crType" accept-charset="utf-8">
                  <div class="form-row ">
                     <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <select
                          @change="cleardiscount"
                          v-model="discount.mode"
                          name="Billingmode"
                          class="form-control form-input"
                          id="modeid"
                          placeholder="Billing Mode"
                          v-validate="'required'"
                        >
                        <option :value=null >  -- Select --  </option>
                        <option v-for="(item,index) in modeList" :key="index" :value="item">
                          {{ item }}
                        </option>
                        </select>
                         <label
                          v-if="errors.first('discountValidate.Billingmode')"
                          class="vi-error"
                          style="top:-1em"
                          >Select Mode is required</label
                        >
                          <label v-else >Select Mode <span class="required">*</span></label>
                        </span>
                     </div>
                     <div class="form-group input-group col-md-4 mt-3">						
                        <span class="has-float-label" v-if="discount.mode == 'Rs($)'">
                        <input type="number" name="disamount"  v-validate="'numeric|min:0'" v-model="discount.discountAmountValue" class="form-control form-input" placeholder="Enter the discount amount" >
                          <label
                          v-if="errors.first('discountValidate.disamount')"
                          class="vi-error"
                          style="top:-1em"
                          >Discount amount is required</label
                        >
                        <label v-else for="pervalid">Discount Amount <span class="required">*</span></label>
                        </span>
                        <span class="has-float-label" v-else>
                        <input type="number" name="penalityAmount"  v-validate="'numeric|min:0'" v-model="discount.discountPercentageValue" class="form-control form-input" id="pervalid" placeholder="Enter the Discount" >
                          <label
                          v-if="errors.first('discountValidate.penalityAmount')"
                          class="vi-error"
                          style="top:-1em"
                          >Discount (%) is required</label
                        > 
                        <label v-else for="pervalid">Discount (%) <span class="required">*</span></label>
                        </span>
                     </div>
                     <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                        <span class="has-float-label">
                           <input  v-model="discount.termPaid"  class="form-control form-input" id="trmpaidlistid" disabled placeholder="Term Paid" >
                           <label for="trmpaidlistid">Term Paid <span class="required">*</span></label>
                         
                        </span>
                     </div>
                  </div>
                  </form>
                  <div class="dispflex mt-3 pt-3" style="border-top:1px solid #e5e5e5">
                     <div class="dk_icon">
                        <!-- <button type="button" class="btn btnsml mpopup" title="Create Custom Field"><i class="fas fa-plus"></i> <span>Custom Field</span></button> -->
                     </div>
                     <div class="text-right dk_iconsml"> 
                        <button type="button" class="btn btnsml" id="svbtn" v-if="isEditAble" :disabled="!discount.mode || ( !discount.discountPercentageValue && discount.mode == 'Percentage(%)') || (!discount.discountAmountValue && discount.mode == 'Rs($)')" @click.prevent="saveDetails('discount')"  >  <span>Update</span></button>
                        <button type="button" :disabled="!discount.mode || ( !discount.discountPercentageValue && discount.mode == 'Percentage(%)') || (!discount.discountAmountValue && discount.mode == 'Rs($)')" class="btn btnsml" id="svbtn" v-else @click.prevent="saveDetails('discount')" >  <span>Save</span></button>
                        <button type="button" class="btn btncl clsmdl" id="clbtn" @click.prevent="closePoup('discount')">   <span>Cancel</span></button>	
                     </div>
                  </div>
                  
               </div>
            </div>
      </b-modal>
      <!-- modal for add penalty -->
      <b-modal id="addPenalty" >
            <div class="contact-form">
               <h2>Add Penalty</h2>
               <br>
               <div>
                   <form data-vv-scope="penalityValidate" id="crtfeeType" accept-charset="utf-8">
                  <div class="form-row">
                      <div class="form-group input-group col-md-3 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <select
                          @change="clearPenality"
                          v-model="penality.mode"
                          name="Billing Frequency"
                          class="form-control form-input"
                          id="modeid"
                          placeholder="Billing Mode"
                          v-validate="'required'"
                        >
                        <option :value=null >  -- Select --  </option>
                        <option v-for="(item,index) in modeList" :key="index" :value="item">
                          {{ item }}
                        </option>
                        </select>

                          <label for="sltmodeid">Select Mode <span class="required">*</span></label>
                        </span>
                     </div>
                     <div class="form-group input-group col-md-3 mt-3">						
                        <span class="has-float-label" v-if="penality.mode == 'Rs($)'">
                        <input type="number" name="perval"  v-validate="'numeric|min:0'" v-model="penality.penaltyAmountValue" class="form-control form-input" id="pervalid" placeholder="Enter the discount amount" >
                        <label for="pervalid">Enter the discount amount <span class="required">*</span></label>
                        </span>
                        <span class="has-float-label" v-else>
                        <input type="number" name="perval" v-validate="'numeric|min:0'" v-model="penality.penaltyPercentageValue" class="form-control form-input" id="pervalid" placeholder="Enter the discount percentage" >
                        <label for="pervalid">Enter the discount percentage <span class="required">*</span></label>
                        </span>
                     </div>
                     <div class="form-group input-group col-md-3 pr-md-3 mt-3">						
                        <span class="has-float-label">
                        <input type="number" min="1" name="daysval" v-validate="'numeric|min:1|max:15'" v-model="penality.paidAfter" class="form-control form-input" id="daysvalid" placeholder="Paid after" >
                        <label for="daysvalid">Paid after<span class="required">*</span></label>
                        <!-- <span style="top:41px">Penality is applicable if paid after</span> -->
                        </span>
                     </div>
                       <div class="form-group input-group col-md-3 pr-md-3 mt-3">						
                        <!-- <span class="has-float-label"> -->
                        <label for="daysvalid">Days from Invoice Due Date </label>
                        <!-- </span> -->
                     </div>
                    
                  </div>
                  <div class="dispflex mt-3 pt-3" style="border-top:1px solid #e5e5e5">
                     <div class="dk_icon">
                     </div>
                     <div class="text-right dk_iconsml">
                       <button type="button" class="btn btnsml" :disabled="!penality.mode || !penality.paidAfter || ( !penality.penaltyPercentageValue && penality.mode == 'Percentage(%)') || (!penality.penaltyAmountValue && penality.mode == 'Rs($)')" id="svbtn" v-if="isEditAble" @click.prevent="saveDetails('penality')" >  <span>Update</span></button>
                        <button type="button" class="btn btnsml" :disabled="!penality.mode || !penality.paidAfter || ( !penality.penaltyPercentageValue && penality.mode == 'Percentage(%)') || (!penality.penaltyAmountValue && penality.mode == 'Rs($)')" v-else id="svbtn" @click.prevent="saveDetails('penality')" >  <span>Save</span></button>
                        <button type="button" class="btn btncl clsmdl" id="clbtn" @click.prevent="closePoup('penality')">   <span>Cancel</span></button>	
                     </div>
                  </div>
                  </form>
               </div>
            </div>
      </b-modal>
      <!-- modal for add rebate -->
     
  </div>
</template>
<script>

import ViService from "@/services/ViService";
import secureUI from "../../utils/secureUI";

export default {
  name: "vi-fees-management",
  data() {
    return {

      feePlanDetails: {},
      discount: {
        mode: 'Rs($)',
        discountAmountValue: '',
        discountPercentageValue: '',
        termPaid: 'Paid in full term'
      },
      penality: {
        mode: 'Rs($)',
        penaltyAmountValue: '',
        penaltyPercentageValue: '',
        paidAfter: null
      },
      discountList: [],
      penalityList: [],
      discountTabActive: true,
      penalityTabActive: false,
      isEditAble: false,
      modeList: [
        "Rs($)",
        "Percentage(%)"
      ]
    };
  },

  methods: {
    clearPenality(){
        this.penality.penaltyAmountValue = '',
        this.penality.penaltyPercentageValue = ''
    },
     cleardiscount(){
        this.discount.discountAmountValue = '',
        this.discount.discountPercentageValue = ''
    },
    redirectRoute(vipath) {
      if (this.$route.path !== vipath) this.$router.push({ path: vipath });
    },
    closePoup(type) {

      if(type == 'discount'){

        this.$bvModal.hide("addDiscount");

        this.discount.mode = 'Rs($)';
        this.discount.discountAmountValue = '';
        this.discount.discountPercentageValue = '';
        this.discount.termPaid = '';
      
      }else if(type == 'penality'){

        this.$bvModal.hide("addPenalty");
        
        this.penality.mode = 'Rs($)';
        this.penality.penaltyAmountValue = '';
        this.penality.penaltyPercentageValue = '';
        this.penality.paidAfter = '';

      }
      
    this.isEditAble = false
    
    },
  
    showFeeTypes() {
      this.$bvModal.show("showFeestype");
    },
    closeShowFeeType() {
      this.$bvModal.hide("showFeestype");
    },
    editDetails(type,id){
      if(type == 'discount'){

        this.discount.mode = this.feePlanDetails.discount.mode;
        this.discount.discountAmountValue = this.feePlanDetails.discount.discountAmountValue;
        this.discount.discountPercentageValue = this.feePlanDetails.discount.discountPercentageValue;
        this.discount.termPaid = this.feePlanDetails.discount.termPaid;

        this.$bvModal.show("addDiscount");

      }else if(type == 'penality'){
       
        this.penality.mode = this.feePlanDetails.penalty.mode;
        this.penality.penaltyAmountValue = this.feePlanDetails.penalty.penaltyAmountValue;
        this.penality.penaltyPercentageValue = this.feePlanDetails.penalty.penaltyPercentageValue;
        this.penality.paidAfter = this.feePlanDetails.penalty.paidAfter;

        this.$bvModal.show("addPenalty");

      }
      
      this.isEditAble = true

    },
    async removeAction(type){
      
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        let dataForm = {};
          
        if(type == 'discount'){
        
        this.discount.mode = '';
        this.discount.discountAmountValue = '';
        this.discount.discountPercentageValue = '';
        this.discount.termPaid = '';

          dataForm['discount'] = this.discount
        }else if(type == 'penality'){

        this.penality.mode = '';
        this.penality.penaltyAmountValue = '';
        this.penality.penaltyPercentageValue = '';
        this.penality.paidAfter = '';

          dataForm['penalty'] = this.penality
        }
        
        const response = await ViService.viXPut(`/feesManagement/createAction/${this.$route.params.id}`,dataForm,userData.token );

        if (response.isSuccess) {

          this.closePoup(type);
          this.getDiscountAndPenalityList();

          if(type == 'discount'){
            this.discountTabActive = true,
            this.penalityTabActive = false
          }else if(type == 'penality'){
            this.discountTabActive = false,
            this.penalityTabActive = true
          }
          
          this.$toasted.success('Deleted Successfully');
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    async saveDetails(type){

      let isFormValid = false;
      let message = "Please fill all the required details";

      if(type == 'discount'){
         await this.$validator.validateAll("discountValidate").then((result) => {
          isFormValid = result;
         });
      }

       if(type == 'penality'){
         await this.$validator.validateAll("penalityValidate").then((result) => {
          isFormValid = result;
         });
      }
      
      if (isFormValid) {
        let userData = secureUI.sessionGet("user");

        if (!userData) {
          this.$toasted.error("Please login and do the action");
        } else {
          let dataForm = {};
           
          if(type == 'discount'){
            dataForm['discount'] = this.discount
          }else if(type == 'penality'){
            dataForm['penalty'] = this.penality
          }
          
          const response = await ViService.viXPut(`/feesManagement/createAction/${this.$route.params.id}`,dataForm,userData.token );

          if (response.isSuccess) {

            this.closePoup(type);
            this.getDiscountAndPenalityList();

            if(type == 'discount'){
              this.discountTabActive = true,
              this.penalityTabActive = false
            }else if(type == 'penality'){
              this.discountTabActive = false,
              this.penalityTabActive = true
            }
            
            this.$toasted.success(response.message);
          } else {
            this.$toasted.error(response.message);
             if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
          }
        }
      } else {
        this.$toasted.error(message);
      }
    },
     
    async getDiscountAndPenalityList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        const response = await ViService.viXGet(
          `/feesManagement/getDiscountAndPenalityList?planId=${this.$route.params.id}`,
          userData.token
        );
        if (response.isSuccess) {

          let resultData = secureUI.secureGet(response.data);
          this.feePlanDetails = resultData
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
  },
};
</script>